import reinforcingLogo from "../images/reinforcing_logo.svg";
import { ToastContainer, toast } from 'react-toastify';
import sendEmail from "../services/emailService.js";
import 'react-toastify/dist/ReactToastify.css';
import * as IoIcons from 'react-icons/io';
import React from 'react';

import "./SocialAndNewsletter.css";

export default function SocialAndNewsletter() {

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;

        sendEmail({ email })
        .then(result => {
            if (result === 0) {
                toast.success('Thank you for subscribing to our newsletter!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            } else if (result === 1) {
                toast.warn('The entered email address is already subscribed to our newsletter.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }
        })
        .catch(error => {
            console.error('There was a problem with the promise:', error);
        });

    };

    return (
        <div className="sub-footer-top">
            <div className="content">
                <div className="sub-footer-col-1">
                    <div id="sub-footer-col-logo">
                        <img src={reinforcingLogo} alt="reinforcing" />
                    </div>
                    <div id="sub-footer-col-text">
                        <strong>Responsible tErritories and Institutions eNable and Foster Open
                            Research and inClusive Innovation for traNsitions Governance</strong>
                    </div>
                    <div className="sub-footer-col-buttons">
                        <a href="https://twitter.com/REINFORCING_eu" target="_blank" rel="noreferrer" className="btn-social-media">
                            <span className="social-media-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                </svg>
                            </span>
                        </a>
                        <a href="https://www.linkedin.com/company/102311771/admin/feed/posts/?feedType=following" target="_blank" rel="noreferrer" className="btn-social-media">
                            <span className="social-media-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                </svg>
                            </span>
                        </a>
                        <a href="https://www.youtube.com/@REINFORCINGeu" target="_blank" rel="noreferrer" className="btn-social-media">
                            <span className="social-media-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="sub-footer-col-2">
                    <div className="sub-footer-col-title">
                        Menu
                    </div>
                    <div className="sub-footer-col-menu">
                        <ul>
                            <li>
                                <a href="/" id="sub-footer-col-menu-link">
                                    Home
                                </a>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <a href="/about" className="dropbtn">About<IoIcons.IoIosArrowDown /></a>
                                    <div className="dropdown-content">
                                        <a href="/about/consortium">CONSORTIUM</a>
                                        <a href="/conference">ORRI FORUM</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <a className="dropbtn">Open Calls<IoIcons.IoIosArrowDown /></a>
                                    <div className="dropdown-content">
                                        <a href="/open-calls/open-calls">GRANTS</a>
                                        <a href="/open-calls/current-calls">CURRENT CALL</a>
                                        <a href="/open-calls/calls-evaluation">CALL FOR EVALUATIONS</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/orri-map" id="sub-footer-col-menu-link">
                                    Orri map
                                </a>
                            </li>
                            <li>
                                <a href="/news" id="sub-footer-col-menu-link">
                                    News
                                </a>
                            </li>
                            <li>
                                <a href="/project-resources" id="sub-footer-col-menu-link">
                                    Resources
                                </a>
                            </li>
                            <li>
                                <a href="/contact" id="sub-footer-col-menu-link">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sub-footer-col-newsletter">
                        <div className="sub-footer-col-newsletter-title">
                            Sign up for the REINFORCING NEWSLETTER
                        </div>
                        <div className="sub-footer-col-newsletter-subtext">
                            Receive the latest exciting news about Reinforcing
                        </div>
                        <form id="sub-footer-col-newsletter-subscribe-form" onSubmit={handleSubmit}>
                            <input type="email" id="form-control" name="email" placeholder="Enter your e-mail" required />
                            <button type="submit" id="sub-footer-col-newsletter-subscribe-btn-2">
                                SUSCRIBE
                            </button>
                        </form>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            </div>
        </div >
    )
}