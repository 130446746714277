import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import homeBackground from '../../images/home/home_icon_new.svg';
import cardR1 from '../../images/home/card_resource_1.svg';
import cardR2 from '../../images/home/card_resource_2.svg';
import cardR3 from '../../images/home/card_resource_3.svg';
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import PostsList from "../../components/PostsList";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import './HomePage.css';

export default function HomePage() {
    return (
        <div className="page-container">
            <Header className="header-home" />
            <div id="home-banner">
                <div className="content">
                    <div className="home-content">
                        <div className="home-txt">
                            <div className="home-header">
                                <h1 id="home-title">
                                    REINFORCING
                                </h1>
                            </div>
                            <div className="home-subheader">
                                <div id="home-subtitle">
                                    The European Platform for Open and Responsible R&amp;I Practices
                                </div>
                            </div>
                            {/* <div className="home-header-button">
                                <a href="/oss-platform" className="home-header-btn">
                                    ACCESS THE PLATFORM
                                </a>
                            </div> */}
                        </div>
                        <div className="home-img">
                            <div className="home-header-logo">
                                <img src={homeBackground} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="home-body" className="content">
                <div className="home-body-text">
                    <p className="home-body-title">
                        REINFORCING supports organizations and institutions in Europe to transition to
                        a new paradigm where responsibility and openness drive research and innovation
                        processes.
                    </p>
                    <p className="home-body-title">
                        The platform provides you with:
                    </p>
                    <div className="home-cards">
                        <div className="card-container">
                            <div className="card">
                                <div className="card-image">
                                    <img src={cardR1} alt="resource" />
                                </div>
                                <div className="card-content">
                                    <h3 className="card-title" id="home-cards-title">Tested resources</h3>
                                    <p className="card-description" id="home-cards-text">
                                        To guide you in your path towards responsible and inclusive research and
                                        innovation practices, our expert partners will curate and organize
                                        resources in different pathways to help you navigate change.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-image">
                                    <img src={cardR2} alt="resource" />
                                </div>
                                <div className="card-content">
                                    <h3 className="card-title" id="home-cards-title">A tailored set of services</h3>
                                    <p className="card-description" id="home-cards-text">
                                        Including training modules, to build capacity and assist you with the
                                        appropriate support during your ORRI practice.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-image">
                                    <img src={cardR3} alt="resource" />
                                </div>
                                <div className="card-content">
                                    <h3 className="card-title" id="home-cards-title">7 open calls</h3>
                                    <p className="card-description" id="home-cards-text">
                                        To financially support your experiments and pilots in Open and Responsible
                                        R&amp;I, both if your organization is a newcomer wishing to embark on ORRI and
                                        if your institution is an experienced entity that would like to strengthen
                                        ORRI implementation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="home-body-button">
                        <a href="/about" className="home-body-btn">
                            learn more about reinforcing
                        </a>
                    </div>
                </div>
            </div>
            <div id="partners-banner">
                <div className="content">
                    <div className="partners-text">
                        <div className="partners-title">
                            <p>
                                Over the past decade, Europe has built a unique body of knowledge in Open and
                                Responsible R&amp;I, but it can be challenging to navigate that wealth of
                                tools and resources.
                            </p>
                        </div>
                        <div className="partners-subtitle">
                            <p>
                                Our 11 partners, world-class practitioners and researchers in Open and
                                Responsible R&amp;I processes are reviewing, curating and organizing this vast
                                amount of work and experiences to put them at the service of European
                                organizations and territories.
                            </p>
                        </div>
                    </div>
                    <div className="partners-button">
                        <a href="/about/consortium" type="button" id="partners-btn-text" className="partners-btn">
                            learn more about our partners
                        </a>
                    </div>
                </div>
            </div>
            <div id="home-conference-banner">
                <div className="conference-title">
                    <div className="conference-title-text">
                        <div className="conference-title-banner">
                            <h1>
                                Join us at OUR WORKING CONFERENCE
                            </h1>
                        </div>
                        <div className="conference-title-title">
                            <h1>
                                The REINFORCING Open and Responsible Research and Innovation Forum
                            </h1>
                        </div>
                        <div className="conference-title-subtitle">
                            <h3>
                                Exploring Global Perspectives and Mainstreaming
                                Responsibility and Openness to Shape the Future of ORRI
                            </h3>
                        </div>
                        <div className="conference-title-button">
                            <form action="https://www.reinforcing.eu/conference" target="_blank">
                                <button className="btn btn-primary" id="conference-title-btn">
                                    <h3>more info</h3>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="conference-info">
                        <div className="conference-title-card">
                            <div className="conference-title-card-subheader">
                                <h3>Dates & Location</h3>
                            </div>
                            <div className="conference-title-card-header">
                                <h1>7-8 october 2024</h1>
                                <h1>Vienna, Austria</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="grants-banner">
                <div className="content">
                    <div className="grants-content">
                        <div className="grants-1">
                            <div className="grants-header">
                                <h1 id="grants-title">
                                    Our grants
                                </h1>
                            </div>
                            <div className="grants-subheader">
                                <h3 id="grants-subtitle">
                                    REINFORCING will launch 7 open calls to fund at least 96 organizations in
                                    their journey. Grants will support projects with between 20,000 and 60,000
                                    euros to encourage institutional and territorial transformation.
                                </h3>
                            </div>
                            <div className="grants-header-button">
                                <a href="/open-calls/open-calls" className="grants-header-btn">
                                    <div id="grants-btn-text">
                                        Discover more about REINFORCING grants
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="grants-2">
                            <div className="grants-text">
                                <h5 id="grants-monthly-title">
                                    July 2024
                                </h5>
                                <h1 id="grants-monthly-body">
                                    3RD REINFORCING Open Call (Incubators Call)
                                </h1>
                                <a href="/open-calls/current-calls" id="grants-monthly-link">
                                    Learn more →
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="info-banner">
                <div className="content">
                    <div id="info-introduction">
                        <div className="grid-container">
                            <div className="column-hp">
                                <div className="info-section-1">
                                    <h1 id="info-title">
                                        Do you want to know more about us?
                                    </h1>
                                </div>
                            </div>
                            <div className="column-hp">
                                <div className="info-section-2">
                                    <h3 id="info-join">
                                        Join our community and take part in the conversation on social media
                                    </h3>
                                </div>
                            </div>
                            <div className="column-hp">
                                <div className="info-section-3">
                                    <span>
                                        <h2 id="info-follow">
                                            FOLLOW US ON
                                        </h2>
                                    </span>
                                    <a href="https://twitter.com/REINFORCING_eu" target="_blank" className="button-circular">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                            </svg>
                                        </span>
                                    </a>
                                    <a href="https://www.linkedin.com/company/102311771/admin/feed/posts/?feedType=following" target="_blank" className="button-circular">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                            </svg>
                                        </span>
                                    </a>
                                    <a href="https://www.youtube.com/@REINFORCINGeu" target="_blank" className="button-circular">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div id="news-card">
                        <div id="news-body">
                            <div className="news-content">
                                <div className="news-1">
                                    <h1 id="news-card-title">
                                        News &amp; Events
                                    </h1>
                                    <div className="news-button">
                                        <a href="/news" type="button" id="news-btn-text" className="news-btn">
                                            view all
                                        </a>
                                    </div>
                                </div>
                                <div className="news-2">
                                    <div className="content">
                                        <div className="news-posts">
                                            <PostsList />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="news-body-2">
                            <div className="news-content">
                                <div className="news-1">
                                    <h1 id="news-card-title">
                                        News &amp; Events
                                    </h1>
                                </div>
                                <div className="news-2">
                                    <div className="news-button">
                                        <a href="/news" type="button" id="news-btn-text" className="news-btn">
                                            view all
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                <div className="news-posts">
                                    <PostsList categoryNumber={5}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Newsletter />
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}