import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import resource from '../../images/conference/resource.svg';
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import speaker_1 from "../../images/conference/speakers/speaker_1.svg";
import speaker_2 from "../../images/conference/speakers/speaker_2.svg";
import speaker_3 from "../../images/conference/speakers/speaker_3.svg";
import speaker_4 from "../../images/conference/speakers/speaker_4.svg";
import speaker_5 from "../../images/conference/speakers/speaker_5.svg";
import speaker_6 from "../../images/conference/speakers/speaker_6.svg";

import './ConferencePage.css';

export default function ConferencePage() {
    return (
        <div className="page-container">
            <Header className="header-conference" />
            <div id="conference-banner">
                <div className="conference-title">
                    <div className="conference-title-title">
                        <h1>
                            The REINFORCING Open and Responsible Research and Innovation Forum
                        </h1>
                    </div>
                    <div className="conference-title-subtitle">
                        <h3>
                            Exploring Global Perspectives and Mainstreaming
                            Responsibility and Openness to Shape the Future of ORRI
                        </h3>
                    </div>
                </div>
                <div className="conference-info">
                    <div className="conference-title-card">
                        <div className="conference-title-card-subheader">
                            <h3>Dates</h3>
                        </div>
                        <div className="conference-title-card-header">
                            <h1>7-8 october 2024</h1>
                        </div>
                    </div>
                    <div className="conference-title-card">
                        <div className="conference-title-card-subheader">
                            <h3>Location</h3>
                        </div>
                        <div className="conference-title-card-header">
                            <h1>vienna, austria</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-container">
                <nav className="about-menu">
                    <ul className="about-ul-menu">
                        <li>
                            <a href="#about-redirection">about</a>
                        </li>
                        <li>
                            <a href="#agenda-redirection">programme</a>
                        </li>
                        <li>
                            <a href="#call-redirection">call for practices</a>
                        </li>
                    </ul>
                </nav>
                <div id="about-redirection" className="about-banner">
                    <div className="about-banner-text">
                        <div className="about-banner-text-title">
                            <h1>about</h1>
                        </div>
                        <div className="about-banner-text-body">
                            <h4>
                                Join us for a dynamic working conference exploring global
                                perspectives on Open Responsible Research and Innovation
                                (ORRI). We will share and discuss innovative ORRI practices
                                (details in our call for practices below) and work towards
                                establishing a solid foundation for the successful mainstreaming
                                of ORRI. Additionally, we will reflect on the future of ORRI.
                            </h4>
                            <h4>
                                The conference will be held both <strong>online (October 7) and
                                on-site (October 7 & 8)</strong> in Vienna Austria. 
                            </h4>
                            <h4>  
                                Please note that on-site participation is by invitation only,
                                with live streaming available exclusively on October 7. For
                                more details, please see the full program below.
                            </h4>
                            <h4>  
                                The ORRI forum is organized by <strong>Austrian Institute of
                                Technology (AIT)</strong> in collaboration with the REINFORCING Consortium.
                            </h4>
                        </div>
                        <form action="https://surveys.ait.ac.at/s3/REINFORCING-International-Conference">
                            <button className="btn btn-primary" id="agenda-btn">
                                <h3>register now!</h3>
                            </button>
                        </form>
                    </div>
                </div>           
            </div>
            {/* <div id="progamme-container">
                <div className="content">
                    <div className="programme-title">
                        <div className="programme-title-back">
                            <h1>programme</h1>
                        </div>
                        <div className="programme-title-front">
                            <h2>programme</h2>
                        </div>
                    </div>
                    <div className="programme-speakers">

                    </div>
                </div>
            </div> */}
            <div id="agenda-container">
                <div id="agenda-redirection" className="content">
                    <div className="agenda-title">
                        <h1>Agenda</h1>
                    </div>
                    <div className="agenda-introduction">
                        <div className="agenda-text">
                            <p>
                                The ORRI forum program features an engaging mix of interactive workshops and
                                expert panels, offering a unique opportunity for participants to delve into
                                groundbreaking research, share valuable insights, and engage in thoughtful
                                discussions. Our expert panels will bring together voices from diverse sectors
                                and regions, providing a comprehensive view of the current landscape of
                                responsible science and innovation, followed by interactive Q&A sessions to
                                deepen understanding.
                            </p>
                            <p> 
                                Please be aware that the agenda outlined below is provisional and will be updated
                                soon. Additionally,all dates and times are in Central European Time <strong>(GMT +1)</strong>.
                            </p>
                        </div>
                    </div>
                    <div className="agenda-back-img">
                        <img src={resource} alt="resource" />
                    </div>
                    <div className="agenda-body">
                        <div className="agenda-column">
                            <div className="agenda-schedule">
                                <div className="agenda-schedule-header">
                                    <div className="agenda-schedule-header-date">
                                        <h2>7 october</h2>
                                    </div>
                                    <div className="agenda-schedule-header-title">
                                        <h1>online & on site</h1>
                                    </div>
                                </div>
                                <div className="agenda-schedule-body">
                                    <ul id="agenda-schedule-body-list">
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>10:30-11:00</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>REINFORCING project - Opening</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Plenary Session</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>11:00-12:30</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Global stORRIes: Presence & Future</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Plenary Session</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>12:30-13:30</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Lunch Break and Networking</h3>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>13:30-16:30</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Sharing Innovative ORRI Practices</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Parallel Working Sessions</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>16:30-17:00</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>REINFORCING ORRI Platform launch</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Plenary Session</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>17:00-17:15</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Wrap up of Day 1</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Plenary Session</h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="agenda-column">
                            <div className="agenda-schedule">
                                <div className="agenda-schedule-header">
                                    <div className="agenda-schedule-header-date">
                                        <h2>8 october</h2>
                                    </div>
                                    <div className="agenda-schedule-header-title">
                                        <h1>on site</h1>
                                    </div>
                                </div>
                                <div className="agenda-schedule-body">
                                    <ul id="agenda-schedule-body-list">
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>9:00-9:15</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Welcome</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Plenary Session</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>9:15-12:00</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Sharing Innovative ORRI Practices</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Parallel Working Sessions</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>12:00-13:00</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Lunch Break and Networking</h3>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>13:00-13:30</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>Experiencing an Integrated Living and Co-Working Community</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Site Tour</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>13:30-15:30</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>REINFORCING workshops</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Working Session</h4>
                                            </div>
                                        </li>
                                        <li id="agenda-schedule-body-list-activity">
                                            <div className="agenda-schedule-body-list-activity-hour">
                                                <h4>15:30-16:30</h4>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-title">
                                                <h3>ORRI Futures - Closing session</h3>
                                            </div>
                                            <div className="agenda-schedule-body-list-activity-subtitle">
                                                <h4>Plenary Session</h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="submission-container">
                <div id="call-redirection" className="content">
                    <div className="speakers-section">
                        <div className="speakers-title">
                            <h1>Speakers</h1>
                        </div>
                        <div className="speakers-body">
                            <div className="speakers-column">
                                <div className="speakers-card" id="speakers-card-row-1">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_1} alt="angela_simone" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Angela Simone</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/angela-simone-b268a56/" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                REINFORCING Coordinator on behalf of Bassetti
                                                Foundation ETS. Expert member of the EU Commission
                                                MLE on Public Engagement in R&I. Adjunct Professor
                                                in RRI at the POLIMI Graduate School of Management.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-2">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_2} alt="david_winickoff" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>David Winickoff</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/david-winickoff-b9762271/?originalSubdomain=fr" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Head of the Responsible Innovation Unit at the
                                                OECD in Paris, David oversees policy on science
                                                and technology. He has over 60 publications and
                                                teaches at SciencesPo Law School. He is a graduate
                                                of Harvard, Yale, and Cambridge, and has served on
                                                expert panels for various global institutions.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-3">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_3} alt="erik_fisher" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Erik Fisher</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/fishererik/ " target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Associate professor at Arizona State University,
                                                directing the Center for Responsible Innovation
                                                and leading the Public Interest Technology MS
                                                program. He edits the Journal of Responsible
                                                Innovation and has developed Socio-Technical
                                                Integration Research for over 80 organizations.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="speakers-column">
                                <div className="speakers-card" id="speakers-card-row-1">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_4} alt="peter_biegelbauer" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Peter Biegelbauer</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/peter-biegelbauer-ba4b72/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=at " target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Senior Scientist at the Center for Innovation Systems
                                                & Policy at the Austrian Institute of Technology in
                                                Vienna (AIT). He leads the AI Ethics Lab, focusing on
                                                AI Ethics and Innovation. He has coordinated research
                                                projects on policy analysis and responsible innovation.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card" id="speakers-card-row-2">
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-img">
                                            <img src={speaker_5} alt="masaru_yamine" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Masaru Yamine</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/yarimemasaru/" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                Associate Professor at HKUST, he, is also a researcher
                                                of science, technology, and innovation policy for
                                                sustainability, focusing on AI, IoT, blockchain, and
                                                smart cities. He serves on editorial boards and holds
                                                degrees from Tokyo, Caltech, and Maastricht.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="speakers-card">
                                    <div className="speakers-card-column" id="speakers-card-row-3">
                                        <div className="speakers-card-img">
                                            <img src={speaker_6} alt="juliana_chaves" />
                                        </div>
                                    </div>
                                    <div className="speakers-card-column">
                                        <div className="speakers-card-title">
                                            <h2>Juliana Chaves</h2>
                                            <a className="btn-social-media" href="https://www.linkedin.com/in/juliana-chaves-chaparro-576b6213/?locale=en_US" target="_blank" rel="noreferrer">
                                                <span className="social-media-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="speakers-card-body">
                                            <h4>
                                                VERITY advisory board member, and Senior consultant
                                                at the UNESCO Management of Social Transformations
                                                Programme Section, She holds 25 years of working
                                                experience in the  promotion of the
                                                science-policy-society interface in the Global South.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="submission-card">
                        <div className="submission-body">
                            <div className="submission-body-column">
                                <div className="submission-body-text">
                                    <div className="submission-body-text-title">
                                        <h1>submission</h1>
                                    </div>
                                    <div className="submission-body-text-subtitle">
                                        <h2>Call for ORRI Practices</h2>
                                    </div>
                                    <div className="submission-body-text-body">
                                        <h4>
                                            In the context of this working conference, we invite you
                                            to participate in our call for ORRI practices, aiming to
                                            provide visibility to specific ORRI practices and network
                                            opportunities with international ORRI practitioners.
                                        </h4>
                                        <h4>   
                                            An ORRI practice represents an innovative approach in which
                                            different actors work together to create knowledge as well
                                            as services and products that are ethically acceptable and
                                            sustainable. We are particularly interested in practices
                                            within research & innovation ecosystems, organizations,
                                            research groups, and networks that offer mechanisms,
                                            instruments, and tools to be inspired and to learn from, so
                                            that other organizations and institutions can translate and
                                            adapt them in their own contexts and settings.
                                        </h4>
                                        <h4>     
                                            Participants will have the opportunity to <strong>present
                                            their practices online (on October 7) or on-site (on October
                                            7 or 8)</strong> during one of the working sessions of the
                                            Forum. The selected ORRI practices will be disclosed during
                                            the week of September 16th.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="submission-body-column">
                                <div className="submission-body-dates">
                                    <div className="submission-body-dates-title">
                                        <h3>Important Dates:</h3>
                                    </div>
                                    <div className="submission-body-dates-list">
                                        <ul>
                                            <li>
                                                <h4>Deadline for submission</h4>
                                                <h3>12/09/2024</h3>
                                            </li>
                                            <li>
                                                <h4>Announcement of the selected practices</h4>
                                                <h3>16/09/2024</h3>
                                            </li>
                                            <li>
                                                <h4>Presentation of the selected practices</h4>
                                                <h3>07/10/2024 or 08/10/2024</h3>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="submission-button">
                                    <form action="https://surveys.ait.ac.at/s3/REINFORCING-International-Conference">
                                        <button className="btn btn-primary" id="submission-btn">
                                            <h3>submit your proposal here</h3>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}